import {
  Container,
  Jumbotron,
  Col,
  Row,
} from "reactstrap";
import mike from "./images/mike.jpg";
import scott from "./images/scott.jpg";

function About() {
    return (
      <Container>
      <Jumbotron className="pb-0" style={{backgroundColor: 'transparent'}}>
          <h1 className="display-3">About Us</h1>
          <p className="lead">
            Weight What is a collaborative effort between us -- web engineers
            and friends Mike Sholar and Scott Gratton. Weight What is an effort to provide
            intuitive and helpful calculators pertaining to fitness and weight lifting.
          </p>
      </Jumbotron>
      <Row className="text-center">
        <Col>
          <img className="rounded-circle mx-auto" src={mike} alt="Mike" width="150"></img>
          <h3>Mike Sholar</h3>
          <p>
            Scott made Mike put this here. Mike loves tech, fitness, and hours of debugging while staring at code!
          </p>
        </Col>
        <Col>
          <img className="rounded-circle mx-auto" src={scott} alt="Scott" width="150"></img>
          <h3>Scott Gratton</h3>
          <p>
            Despite general unfamiliarity with lifting weights, Scott loves to build applications,
            record and play music, rock climb, skateboard and hang out with his wife and two crazy kids.
          </p>
        </Col>
      </Row>
      </Container>
    )
  }

  export default About;