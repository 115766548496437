import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Badge,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
import "./weight-calculator.css";
import _ from "lodash";
import classnames from "classnames";
import gear from "./gear.svg";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-44831702-2";
ReactGA.initialize(TRACKING_ID);

const allPlates = {
  lb: [
    { weight: 55, width: 32, height: 90, color: "#a23936", defaultCount: 0 },
    { weight: 45, width: 31, height: 90, color: "#0466b4", defaultCount: 5 },
    { weight: 35, width: 30, height: 90, color: "#e6bc28", defaultCount: 5 },
    { weight: 25, width: 29, height: 90, color: "#1bad3e", defaultCount: 5 },
    { weight: 15, width: 28, height: 90, color: "#8a8f91", defaultCount: 5 },
    { weight: 10, width: 27, height: 70, color: "#e6e5e3", defaultCount: 5 },
    { weight: 5, width: 26, height: 70, color: "#ad2825", defaultCount: 5 },
    { weight: 2.5, width: 25, height: 70, color: "#41b957", defaultCount: 5 },
  ],
  kg: [
    { weight: 25, width: 32, height: 90, color: "#933436", defaultCount: 0 },
    { weight: 20, width: 31, height: 90, color: "#315b8d", defaultCount: 5 },
    { weight: 15, width: 30, height: 90, color: "#f0b200", defaultCount: 5 },
    { weight: 10, width: 29, height: 90, color: "#30b03c", defaultCount: 5 },
    { weight: 5, width: 28, height: 90, color: "#9d9d9d", defaultCount: 5 },
    { weight: 2.5, width: 27, height: 70, color: "#a9080f", defaultCount: 5 },
    { weight: 1.25, width: 26, height: 70, color: "#c4c7c9", defaultCount: 5 },
  ],
};

let defaultPlates = {};
Object.entries(allPlates).forEach(
  (plateSystem) =>
    (defaultPlates[plateSystem[0]] = plateSystem[1].map((plate) => ({
      weight: plate.weight,
      count: plate.defaultCount,
    })))
);

const barWeights = {
  lb: [45, 35, 15],
  kg: [20, 15],
};
const systems = ["lb", "kg"];
const plateSetLabels = [
  "Deadlift",
  "Front Squat",
  "Back Squat",
  "Snatch",
  "Clean and Jerk",
];
const collarWeights = {
  lb: 11,
  kg: 5,
};

class WeightCalculator extends Component {
  constructor(props) {
    super(props);
    const system = localStorage.getItem("system");
    const barWeight = localStorage.getItem("barWeight" + system);
    const availablePlates =
      JSON.parse(localStorage.getItem("availablePlates" + system)) ||
      defaultPlates[system] ||
      [];
    this.state = {
      barWeight,
      totalWeight: 0,
      percentWeight: 100,
      currentPlates: [],
      savedPlateSets: JSON.parse(localStorage.getItem("savedPlateSets")) || [],
      system,
      showConfig: !system || !barWeight,
      plateSetLabel: "",
      activeTab: localStorage.getItem("activeTab") || "calculator",
      showSavedPopover: false,
      availablePlates,
      includeCollar: localStorage.getItem("includeCollar") || false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleRemoveSet = this.handleRemoveSet.bind(this);
    this.toggleConfiguration = this.toggleConfiguration.bind(this);
    this.handleToggleTab = this.handleToggleTab.bind(this);
    this.handleChangePlateConfig = this.handleChangePlateConfig.bind(this);
    this.handleChangeCollarCheck = this.handleChangeCollarCheck.bind(this);
  }

  static round5(x) {
    return Math.ceil(x / 5) * 5;
  }

  static convertLbToKg(weight) {
    return Math.round(weight * 0.45359237);
  }

  static convertKgToLb(weight) {
    return Math.round(weight / 0.45359237);
  }

  convertSystem(newSystem) {
    const { savedPlateSets } = this.state;
    if (newSystem === "kg") {
      savedPlateSets?.map((plateSet) => {
        plateSet.totalWeight = WeightCalculator.convertLbToKg(
          plateSet.totalWeight
        );
        return plateSet;
      });
    } else if (newSystem === "lb") {
      savedPlateSets?.map((plateSet) => {
        plateSet.totalWeight = WeightCalculator.convertKgToLb(
          plateSet.totalWeight
        );
        return plateSet;
      });
    }
  }

  handleToggleTab(event) {
    if (event.target.name == null) return false;

    this.setState({ activeTab: event.target.name });
    this.setState({ showSavedPopover: false });
    localStorage.setItem("activeTab", event.target.name);
  }

  handleChange(event) {
    const { system } = this.state;
    if (event.target.name === "system") {
      const barWeight =
        localStorage.getItem("barWeight" + event.target.value) || 0;
      this.setState({ barWeight });
      localStorage.setItem("system", event.target.value);
      this.convertSystem(event.target.value);
      const availablePlates =
        JSON.parse(
          localStorage.getItem("availablePlates" + event.target.value)
        ) || defaultPlates[event.target.value];
      this.setState({ availablePlates });
    }
    if (event.target.name === "barWeight") {
      localStorage.setItem(
        "barWeight" + system,
        parseFloat(event.target.value)
      );
    }

    this.setState({ [event.target.name]: event.target.value }, function () {
      this.calculatePlates();
      this.setState({ showSavedPopover: false });
    });
  }

  handleChangePlateConfig(event) {
    const { availablePlates, system } = this.state;
    let updatedPlates = availablePlates;
    const plateIndexToUpdate = updatedPlates.findIndex(
      (p) => parseFloat(p.weight) === parseFloat(event.target.name)
    );
    updatedPlates[plateIndexToUpdate] = {
      weight: parseFloat(event.target.name),
      count: parseFloat(event.target.value),
    };
    this.setState({ availablePlates: updatedPlates });
    localStorage.setItem(
      "availablePlates" + system,
      JSON.stringify(updatedPlates)
    );
  }

  handleChangeCollarCheck() {
    const { includeCollar } = this.state;

    localStorage.setItem("includeCollar", !includeCollar);
    this.setState({ includeCollar: !includeCollar });
  }

  currentPlateSetCard() {
    const {
      currentPlates,
      totalWeight,
      percentWeight,
      plateSetLabel,
      showSavedPopover,
      barWeight,
    } = this.state;
    const plateSet = {
      currentPlates,
      totalWeight,
      percentWeight,
      plateSetLabel,
    };
    if (currentPlates.length > 8) {
      return (
        <Alert color="info">
          Try removing some weight. Weight What doesn't yet support this amount.
        </Alert>
      );
    }

    if (barWeight === 0) {
      return false;
    }

    return (
      <div>
        {showSavedPopover && <Alert color="info">Saved to Plate Sets!</Alert>}
        {this.plateInfoCard(plateSet, false, null)}
      </div>
    );
  }

  labelAndSaveButton() {
    const { currentPlates, plateSetLabel } = this.state;
    const disableSave = currentPlates.length < 1;

    return (
      <Row>
        <Col>
          <Form onChange={this.handleChange}>
            <FormGroup>
              <Input
                defaultValue={plateSetLabel}
                type="text"
                name="plateSetLabel"
                list="plateSetLabels"
                placeholder="Label"
              />
              <datalist id="plateSetLabels">
                {plateSetLabels.map((label) => (
                  <option value={label}>{label}</option>
                ))}
              </datalist>
            </FormGroup>
          </Form>
        </Col>
        <Col className="float-left m-0 p-0" xs="3">
          <Button
            size="md"
            color="primary"
            onClick={this.handleSave}
            disabled={disableSave}
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  }

  handleSave() {
    const { savedPlateSets, totalWeight, percentWeight, plateSetLabel } =
      this.state;
    let updatedPlateSets = savedPlateSets;
    let plateSet = {
      totalWeight,
      percentWeight,
      plateSetLabel,
    };
    updatedPlateSets.push(plateSet);
    this.setState({ savedPlateSets: updatedPlateSets });
    this.setState({ showSavedPopover: true });
    localStorage.setItem("savedPlateSets", JSON.stringify(updatedPlateSets));
  }

  handleClear() {
    this.setState({ savedPlateSets: [] });
    localStorage.removeItem("savedPlateSets");
  }

  handleRemoveSet(index) {
    const { savedPlateSets } = this.state;
    let updatedPlateSets = savedPlateSets;
    updatedPlateSets.splice(index, 1);
    this.setState({ savedPlateSets: updatedPlateSets });
    localStorage.setItem("savedPlateSets", JSON.stringify(updatedPlateSets));
  }

  plateInfoCard(plateSet, closeButton, index) {
    const { barWeight, system, includeCollar } = this.state;
    const plates = this.calculatePlatesArray(
      plateSet.totalWeight,
      plateSet.percentWeight
    );
    let totalPlateWeight = (plates?.reduce((a, b) => a + b, 0) || 0) * 2;
    const collarWeight = includeCollar ? collarWeights[system] : 0;
    let plateAndBarTotal =
      totalPlateWeight + parseFloat(barWeight) + parseFloat(collarWeight);
    let platePosition = 58;
    return (
      <Card className="plate-info-outer-card mb-2">
        <CardBody>
          <span className="plate-set-label">{plateSet.plateSetLabel}</span>
          <div className="plate-info-card mt-3 ml-3">
            {barWeight > 0 && (
              <div>
                <div className="bar"></div>
                <div className="bar-plate-part"></div>
                <div className="bar-nub"></div>
                <div className="bar-weight">
                  <small>{barWeight}</small>
                </div>
              </div>
            )}
            {plates?.map((plate) => {
              const plateObject = allPlates[system].find(
                (p) => parseFloat(p.weight) === parseFloat(plate)
              );
              const currentPosition = platePosition;
              platePosition += plateObject.width;
              return this.plateImage(plateObject, currentPosition);
            })}
            {this.collar(platePosition)}
          </div>
        </CardBody>
        <CardFooter>
          {plateAndBarTotal} {system}{" "}
          {plates.length > 0 && (
            <span>
              ({plateSet.percentWeight}% of {plateSet.totalWeight} {system})
            </span>
          )}
          {closeButton && (
            <Button onClick={() => this.handleRemoveSet(index)} close />
          )}
        </CardFooter>
      </Card>
    );
  }

  plateImage(plateObject, platePosition) {
    const plateStyle = {
      left: platePosition + "px",
      background: plateObject.color,
      width: plateObject.width + "px",
      height: plateObject.height + "px",
      lineHeight: plateObject.height - 5 + "px",
    };
    return (
      <div className="plate" style={plateStyle}>
        <small>{plateObject.weight}</small>
      </div>
    );
  }

  collar(platePosition) {
    const { includeCollar, system } = this.state;
    if (!includeCollar) {
      return false;
    }

    const plateStyle = {
      left: platePosition + "px",
    };
    return (
      <div className="plate text-nowrap" style={plateStyle}>
        <small>{collarWeights[system] / 2}</small>
      </div>
    );
  }

  calculatePlates() {
    const { totalWeight, percentWeight } = this.state;

    this.setState({
      currentPlates: this.calculatePlatesArray(totalWeight, percentWeight),
    });
  }

  calculatePlatesArray(totalWeight, percentWeight) {
    let { barWeight, availablePlates, includeCollar, system } = this.state;

    totalWeight = parseFloat(totalWeight);
    percentWeight = parseFloat(percentWeight);
    barWeight = parseFloat(barWeight);
    const collarWeight = includeCollar ? collarWeights[system] : 0;
    let weight = WeightCalculator.round5(totalWeight);

    let adjusted_weight = WeightCalculator.round5(
      (weight * percentWeight) / 100.0
    );
    let plateWeight = adjusted_weight - barWeight - collarWeight;
    plateWeight = plateWeight / 2;

    let plates = [];
    let plateCount = 0;
    let amountAvailable = 0;
    availablePlates
      .map((plate) => plate.weight)
      .sort(function (a, b) {
        return b - a;
      })
      .forEach(function (plate) {
        if (plateWeight >= plate) {
          amountAvailable = availablePlates.find(
            (p) => parseFloat(p.weight) === parseFloat(plate)
          ).count;
          plateCount = Math.min(
            Math.floor(plateWeight / plate),
            amountAvailable
          );
          _.times(plateCount, () => plates.push(plate));
          plateWeight = plateWeight - plate * plateCount;
        }
      });

    return plates;
  }

  plateSetsArea() {
    const { savedPlateSets } = this.state;

    if (savedPlateSets.length === 0) {
      return (
        <Alert color="info">Save plate sets on the calculator section</Alert>
      );
    }

    return (
      <div>
        <Button
          className="ml-2 mb-2"
          size="sm"
          color="warning"
          onClick={this.handleClear}
        >
          Clear All
        </Button>
        {savedPlateSets.map((plateSet, index) =>
          this.plateInfoCard(plateSet, true, index)
        )}
      </div>
    );
  }

  plateCountConfig(weight) {
    const { availablePlates, system } = this.state;
    const plate = availablePlates.find(
      (p) => parseFloat(p.weight) === parseFloat(weight)
    );
    const plateObject = allPlates[system].find(
      (p) => parseFloat(p.weight) === parseFloat(weight)
    );
    const amountAvailable = plate.count;

    return (
      <FormGroup key={weight}>
        <Label for="availablePlates" sm={2} className="pb-1">
          <Badge style={{ backgroundColor: plateObject.color }}>
            {weight}'s
          </Badge>
        </Label>
        <Col>
          <Input
            defaultValue={amountAvailable}
            type="select"
            name={weight}
            id="availablePlates"
          >
            {Array.from(Array(6).keys()).map((number) => (
              <option>{number}</option>
            ))}
          </Input>
        </Col>
      </FormGroup>
    );
  }

  toggleConfiguration() {
    const { showConfig } = this.state;
    this.setState({ showConfig: !showConfig });
  }

  configSettings() {
    const { system, barWeight, showConfig, includeCollar } = this.state;

    return (
      <Collapse className=" mb-2 mx-1" isOpen={showConfig}>
        <Card>
          <CardHeader className="p-2">
            <h6 className="mt-1 ml-1">
              <img src={gear} alt="settings" /> Settings
              <Button
                className="float-right"
                outline
                color="secondary"
                size="sm"
                onClick={this.toggleConfiguration}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-caret-up-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
              </Button>
            </h6>
          </CardHeader>
          {(!system || !barWeight) && (
            <Alert color="info">
              To get started, set the desired system and bar weight!
            </Alert>
          )}
          <Form onChange={this.handleChange}>
            <Row className="pt-1 mb-0 mx-1">
              <Col xs="auto">
                <FormGroup tag="fieldset" className="m-0">
                  <Label for="system">System</Label>
                  {systems.map((systemOption) => (
                    <FormGroup key={systemOption} check>
                      <Label check>
                        <Input
                          type="radio"
                          name="system"
                          value={systemOption}
                          defaultChecked={systemOption === system}
                        />
                        {systemOption}
                      </Label>
                    </FormGroup>
                  ))}
                </FormGroup>
              </Col>
              <Col xs="auto">
                <FormGroup tag="fieldset" className="m-0">
                  <Label for="barWeight">Bar Weight</Label>
                  {barWeights[system]?.map((number) => (
                    <FormGroup key={number + system} check>
                      <Label check>
                        <Input
                          type="radio"
                          name="barWeight"
                          value={number}
                          defaultChecked={
                            parseFloat(number) === parseFloat(barWeight)
                          }
                        />
                        {number} {system}s
                      </Label>
                    </FormGroup>
                  ))}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {system && (
            <div>
              <hr />
              <Row>
                <Col xs="auto">
                  <FormGroup
                    check
                    onChange={this.handleChangeCollarCheck}
                    className="ml-3"
                  >
                    <Label check>
                      <Input type="checkbox" defaultChecked={includeCollar} />{" "}
                      <small>
                        Use Competition Collars (+{collarWeights[system]}{" "}
                        {system} total)
                      </small>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Form onChange={this.handleChangePlateConfig} tag="fieldset">
                <Label className="ml-3" for="availablePlates">
                  Available Plates (Pairs)
                </Label>
                <Row className="pt-1 mb-2 mx-1 text-nowrap">
                  {allPlates[system]?.map((plateObject) =>
                    this.plateCountConfig(plateObject.weight)
                  )}
                </Row>
              </Form>
            </div>
          )}
        </Card>
      </Collapse>
    );
  }

  render() {
    ReactGA.event({
      category: "User",
      action: "Loaded Page",
    });

    const { system, savedPlateSets, activeTab, percentWeight, barWeight } =
      this.state;

    return (
      <Container className="weight-calculator-container p-4 mt-3 ml-4">
        <Button
          className="float-right ml-2"
          outline
          color="secondary"
          size="sm"
          onClick={this.toggleConfiguration}
        >
          <img src={gear} alt="settings-toggle" />
        </Button>
        <Nav onClick={this.handleToggleTab} pills>
          <NavItem className="mb-2">
            <NavLink
              name="calculator"
              className={classnames({ active: activeTab === "calculator" })}
            >
              Calculator
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              name="plate-sets"
              className={classnames({ active: activeTab === "plate-sets" })}
            >
              Plate Sets{" "}
              <Badge className="pt-1" color="secondary">
                {savedPlateSets.length}
              </Badge>
            </NavLink>
          </NavItem>
        </Nav>
        {this.configSettings()}
        <TabContent activeTab={activeTab}>
          {system && !!barWeight && (
            <TabPane tabId="calculator">
              {this.currentPlateSetCard()}
              <Form onChange={this.handleChange}>
                <InputGroup className="mb-2">
                  <Input
                    type="number"
                    pattern="\d*"
                    name="totalWeight"
                    id="totalWeight"
                    placeholder="Total Weight"
                    autoComplete="off"
                  />
                  <InputGroupAddon addonType="append">{system}</InputGroupAddon>
                </InputGroup>
                <InputGroup className="mb-2">
                  <Input
                    type="number"
                    pattern="\d*"
                    name="percentWeight"
                    id="percentWeight"
                    placeholder="Percent"
                    defaultValue={percentWeight}
                    autoComplete="off"
                    invalid={!percentWeight}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
              {this.labelAndSaveButton()}
            </TabPane>
          )}
          <TabPane tabId="plate-sets">{this.plateSetsArea()}</TabPane>
        </TabContent>
      </Container>
    );
  }
}

export default WeightCalculator;
