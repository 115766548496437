import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardFooter,
  Collapse,
} from "reactstrap";
import "./plate-adder.css";
import gear from "./gear.svg";


const allPlates = {
  lb: [
    { weight: 55, width: 30, height: 90, color: "#a23936"},
    { weight: 45, width: 29, height: 90, color: "#0466b4"},
    { weight: 35, width: 28, height: 90, color: "#e6bc28"},
    { weight: 25, width: 27, height: 90, color: "#1bad3e"},
    { weight: 15, width: 26, height: 90, color: "#8a8f91"},
    { weight: 10, width: 25, height: 70, color: "#e6e5e3"},
    { weight: 5, width: 24, height: 70, color: "#ad2825"},
    { weight: 2.5, width: 23, height: 70, color: "#41b957"},
  ],
  kg: [
    { weight: 25, width: 30, height: 90, color: "#933436"},
    { weight: 20, width: 30, height: 90, color: "#315b8d"},
    { weight: 15, width: 29, height: 90, color: "#f0b200"},
    { weight: 10, width: 28, height: 90, color: "#30b03c"},
    { weight: 5, width: 29, height: 90, color: "#9d9d9d"},
    { weight: 2.5, width: 28, height: 70, color: "#a9080f"},
    { weight: 1.25, width: 27, height: 70, color: "#c4c7c9"},
  ],
};

const barWeights = {
  lb: [45, 35, 15],
  kg: [20, 15],
};
const systems = ["lb", "kg"];

class PlateAdder extends Component {
  constructor(props) {
    super(props);
    const system = localStorage.getItem("system");
    const barWeight = localStorage.getItem("barWeight" + system);
    this.state = {
      barWeight,
      totalWeight: 0,
      currentPlates: [],
      system,
      showConfig: !system || !barWeight,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddPlate = this.handleAddPlate.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleRemoveLastPlate = this.handleRemoveLastPlate.bind(this);
    this.toggleConfiguration = this.toggleConfiguration.bind(this);
  }

  handleChange(event) {
    const { system } = this.state;
    if (event.target.name === "system") {
      this.setState({ currentPlates: [] });
      const barWeight =
        localStorage.getItem("barWeight" + event.target.value) || 0;
      this.setState({ barWeight });
      localStorage.setItem("system", event.target.value);
    }
    if (event.target.name === "barWeight") {
      localStorage.setItem(
        "barWeight" + system,
        parseFloat(event.target.value)
      );
    }

    this.setState({ [event.target.name]: event.target.value }, function () {
      this.setState({ showSavedPopover: false });
      // close config if everything is set
      const configPending = !this.state.system || !this.state.barWeight;
      const updateState = () => this.setState({ showConfig: configPending });
      setTimeout(function () {
        updateState();
      }, 300);
    });
  }

  handleAddPlate(plate) {
    const { currentPlates } = this.state;
    let updatedPlates = currentPlates
    updatedPlates.push(plate)
    updatedPlates.sort(function (a, b) {
      return b - a;
    })

    this.setState({ currentPlates: updatedPlates })
  }

  handleClear() {
    this.setState({ currentPlates: [] })
  }

  handleRemoveLastPlate() {
    const { currentPlates } = this.state;
    let updatedPlates = currentPlates
    updatedPlates.pop()

    this.setState({ currentPlates: updatedPlates })
  }


  plateInfoCard() {
    const { barWeight, system, currentPlates } = this.state;
    let totalPlateWeight = (currentPlates?.reduce((a, b) => a + b, 0) || 0) * 2;
    let plateAndBarTotal = totalPlateWeight + parseFloat(barWeight);
    let platePosition = 58;
    let lastPlate = {};
    return (
      <Card className="plate-info-outer-card mb-2">
        <CardBody>
          <div className="plate-info-card mt-3 ml-3">
            {barWeight > 0 && (
              <div>
                <div className="bar"></div>
                <div className="bar-plate-part"></div>
                <div className="bar-nub"></div>
                <div className="bar-weight">
                  <small>{barWeight}</small>
                </div>
              </div>
            )}
            {currentPlates?.map((plate) => {
              const plateObject = allPlates[system].find(
                (p) => parseFloat(p.weight) === parseFloat(plate)
              );
              lastPlate = plateObject
              const currentPosition = platePosition;
              platePosition += plateObject.width;
              return this.plateImage(plateObject, currentPosition);
            })}
            {currentPlates.length > 0 &&
              <Button
                style={{
                  position: 'absolute',
                  left: (platePosition - (lastPlate.width / 2) - 7) + 'px',
                  top: '0',
                }}
                onClick={this.handleRemoveLastPlate}
                close
              />
            }
          </div>
        </CardBody>
        <CardFooter>
          {plateAndBarTotal} {system}
        </CardFooter>
      </Card>
    );
  }

  plateImage(plateObject, platePosition) {
    const plateStyle = {
      left: platePosition + "px",
      background: plateObject.color,
      width: plateObject.width + "px",
      height: plateObject.height + "px",
      lineHeight: plateObject.height - 5 + "px",
    };
    const key = ['plate-image', plateObject.weight, platePosition].join('-')
    return (
      <div key={key} className="plate" style={plateStyle}>
        <small>{plateObject.weight}</small>
      </div>
    );
  }

  toggleConfiguration() {
    const { showConfig } = this.state;
    this.setState({ showConfig: !showConfig });
  }

  plateChooser() {
    const { system } = this.state;

    return (
      <Row className="ml-3 mt-4">
        {allPlates[system]?.map((plateObject) => {
          return (
            <Col xs="3" key={['plate-chooser', plateObject.weight].join('-')}>
              <div
                style={{height: '100px', cursor: 'pointer'}}
                onClick={() => this.handleAddPlate(plateObject.weight)}>
                {this.plateImage(plateObject, 0)}
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }

  render() {
    const {
      system,
      barWeight,
      showConfig,
      currentPlates,
    } = this.state;

    return (
      <Container className="weight-calculator-container p-4 mt-3 ml-4">
        <Button onClick={this.handleClear} className="mb-3" color="warning" disabled={currentPlates.length === 0}>
          Clear
        </Button>
        <Button
          className="float-right ml-2"
          outline
          color="secondary"
          size="sm"
          onClick={this.toggleConfiguration}
        >
          <img src={gear} alt="settings-toggle" />
        </Button>
        <Collapse
          className="configuration-settings mb-2 mx-1"
          isOpen={showConfig}
        >
          <Form onChange={this.handleChange}>
            <h6 className="mt-1 ml-1">
              <img src={gear} alt="settings" /> Settings
            </h6>
            <Row className="pt-1 mb-2 mx-1">
              <Col xs="auto">
                <FormGroup tag="fieldset">
                  <Label for="system">System</Label>
                  {systems.map((systemOption) => (
                    <FormGroup key={systemOption} check>
                      <Label check>
                        <Input
                          type="radio"
                          name="system"
                          value={systemOption}
                          defaultChecked={systemOption === system}
                        />
                        {systemOption}
                      </Label>
                    </FormGroup>
                  ))}
                </FormGroup>
              </Col>
              <Col xs="auto">
                <FormGroup tag="fieldset">
                  <Label for="barWeight">Bar Weight</Label>
                  {barWeights[system]?.map((number) => (
                    <FormGroup key={number + system} check>
                      <Label check>
                        <Input
                          type="radio"
                          name="barWeight"
                          value={number}
                          defaultChecked={
                            parseFloat(number) === parseFloat(barWeight)
                          }
                        />
                        {number} {system}s
                      </Label>
                    </FormGroup>
                  ))}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Collapse>
          {this.plateInfoCard()}
          {this.plateChooser()}
      </Container>
    );
  }
}

export default PlateAdder;
