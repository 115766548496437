import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import NavBar from './nav-bar';
import WeightCalculator from './weight-calculator';
import MacrosCalculator from './macros-calculator';
import PlateAdder from './plate-adder';
import About from './about';

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Switch>
          <Redirect exact from="/" to="/plate_calculator" />
          <Route path="/plate_calculator">
            <WeightCalculator />
          </Route>
          <Route path="/plate_adder">
            <PlateAdder />
          </Route>
          <Route path="/macros_calculator">
            <MacrosCalculator />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
