import React, { useState } from "react";
import logo from "./logo.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Badge,
  Button,
  NavLink,
  NavItem,
} from "reactstrap";
import { Link } from "react-router-dom";

const WeightWhatNav = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar style={{ backgroundColor: "#cc2e01" }} dark expand="md">
        <NavbarBrand>
          <img src={logo} alt="logo" height="40" />
          <Badge style={{ fontSize: "50%" }} color="dark">
            beta
          </Badge>
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar>
          <Nav pills className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/plate_calculator" color="light">
                Plate Calculator
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/plate_adder" color="light">
                Plate Adder
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/macros_calculator">
                Macros
                <Badge style={{ fontSize: "50%" }} color="secondary">
                  Coming Soon!
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about" color="light">
                About
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav className="mr-auto" navbar></Nav>
        <Button
          href="https://forms.gle/jdSqS5z9VeTtJNcRA"
          className="float-right"
          size="sm"
          color="info"
        >
          Share Feedback
        </Button>
        <NavbarToggler className="ml-3" onClick={toggle} />
      </Navbar>
    </div>
  );
};

export default WeightWhatNav;
